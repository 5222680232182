import TheReviewIndex from "../components/home/theReviewIndex"
import { landingLayoutParams } from "../components/layouts/landingLayout"
import { getFooterLinks } from "../components/tri/triLandingUnit"
import Custom404 from "./404"
import { useRouter } from "next/router"
import { THEME, SITE } from "../configs/theme"

Country.getLayout = landingLayoutParams({ getFooterLinks })

export default function Country() {
  const router = useRouter()
  if (SITE == "tri") return <TheReviewIndex />

  return <Custom404 />
}
